import { FC } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import styles from './page-loader.module.scss';
interface PageLoaderProps {
  text?: string;
}
const PageLoader: FC<PageLoaderProps> = ({ text }) => {
  return (
    <div className={styles['page-loader']}>
      <img
        className={styles['logo']}
        src="/gannet-green.png"
        height="150"
        alt="Gannet green logo"
      />
      {text && <p className={styles['text']}>{text}</p>}
      <LoadingSpinner />
    </div>
  );
};

export default PageLoader;
