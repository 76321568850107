import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import Home from '@/pages/Home';
import { SessionProvider } from '@/context/SessionProvider';
import PageLoader from '../PageLoader';

const BrowserRoutes = () => {
  const location = window.location;

  useLayoutEffect(() => {
    if (location.pathname !== '/') {
      const newUrl = `https://app.gannet.ai${location.pathname.replace(
        '/app',
        ''
      )}${location.search}`;
      setTimeout(() => {
        window.location.replace(newUrl);
      }, 1000);
    }
  }, [location]);

  return (
    <BrowserRouter>
      <SessionProvider>
        <Routes>
          <Route index element={<Home />} />
          <Route
            path="*"
            element={<PageLoader text="Redirecting to app.gannet.ai" />}
          />
        </Routes>
      </SessionProvider>
    </BrowserRouter>
  );
};

export default BrowserRoutes;
